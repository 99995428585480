@font-face {
  font-family: VerelaRound-Regular;
  src: url("VarelaRound-Regular.65239737.ttf") format("truetype");
}

body {
  --standard-space: 40px;
  margin: 0;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
}

body .light-mode {
  --accent-color: #f59e0b;
  --special-accent-color: #f7f827;
  --primary-color: #4a90e2;
  --secondary-color: #f4f4f4;
  --text-color: #333;
  --background-color: transparent;
  --opaque-background-color: #fff;
  --special-color: blueviolet;
  --special-color2: #31ab98;
  --table-border-color: #ddd;
  --table-header-bg: #959af1a3;
  --table-header-color: #333;
  --table-row-bg: #fff;
  --table-row-alt-bg: #f9f9f9;
  --table-font-color: #333;
  --table-padding: 10px;
}

body .dark-mode {
  --accent-color: #959af1a3;
  --primary-color: #6a90e2;
  --secondary-color: #44f4f4;
  --text-color: #f4f4f4;
  --background-color: #333;
  --opaque-background-color: #222;
  --special-color: blueviolet;
  --special-color2: #31ab98;
  --table-border-color: #ddd;
  --table-header-bg: #959af1a3;
  --table-header-color: #333;
  --table-row-bg: #fff;
  --table-row-alt-bg: #f9f9f9;
  --table-font-color: #333;
  --table-padding: 10px;
}

@media (width <= 1000px) {
  body {
    --standard-space: 20px;
    font-size: 3vw;
  }
}

h1, h2, h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  font-weight: bold;
}

.header {
  background-color: var(--primary-color);
  border-bottom: 5px solid var(--secondary-color);
  margin-bottom: 1em;
  padding: 1em;
}

.header .title {
  justify-content: space-between;
  margin-top: 1rem;
  display: flex;
}

.header .title title {
  color: var(--opaque-background-color);
  font-size: 1.4rem;
  display: block;
}

@media (width >= 1400px) {
  .header .title title {
    font-size: 2rem;
  }
}

.header .title .logo {
  justify-self: end;
  margin-top: -1em;
}

.header .title .logo img {
  width: auto;
  height: 8em;
}

.header h1 {
  font-size: 1em;
}

@media screen and (width <= 1000px) {
  .header .logo img {
    width: auto;
    height: 6em;
  }
}

.header h1 {
  color: var(--secondary-color);
  font-size: 2rem;
  font-weight: bold;
}

nav {
  background-color: var(--opaque-background-color);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  padding: .5em 0;
  font-weight: bold;
  list-style: none;
  display: flex;
  box-shadow: 0 2px 5px #0003;
}

nav li {
  margin: 3px 1em;
  font-size: 1.2rem;
  display: flex;
}

nav li a {
  color: #333;
  flex-grow: 1;
  padding: 5px 10px;
  text-decoration: none;
}

nav li .selected {
  color: var(--opaque-background-color);
  background-color: var(--primary-color);
}

nav li:hover > ul {
  display: block;
}

nav li a {
  border-radius: 4px;
  padding: .5em 1em;
  text-decoration: none;
}

nav li a:hover {
  background-color: var(--primary-color);
  color: var(--opaque-background-color);
}

nav ul {
  background: var(--opaque-background-color);
  z-index: 10;
  border: 1px solid #000;
  border-radius: .25em;
  margin-top: 2em;
  list-style: none;
  display: none;
  position: absolute;
  left: auto;
  box-shadow: 0 8px 16px #0003;
}

@media (width <= 1024px) {
  nav ul {
    right: 8em;
  }
}

nav ul li a {
  padding: 10px 20px;
}

.main {
  flex-direction: column;
  padding: 1em;
  display: grid;
}

.main .section {
  margin-bottom: 1em;
}

.main .section h2 {
  color: var(--secondary-color);
  margin-bottom: .5em;
  font-size: 1.5em;
  font-weight: bold;
}

.main .section p {
  color: var(--primary-color);
  font-size: 1em;
  line-height: 1.5;
}

details {
  background-color: var(--secondary-color);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px #0000001a;
}

details summary {
  color: var(--primary-color);
  cursor: pointer;
  outline: none;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  font-weight: bold;
}

details summary:hover {
  color: var(--accent-color);
}

details summary::-webkit-details-marker {
  display: none;
}

details p {
  margin-top: 10px;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  line-height: 1.6;
}

a, button {
  color: var(--primary-color);
  transition: color .3s, transform .3s, filter .3s;
}

@keyframes fadeInWithTransparency {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.content {
  animation: 1s ease-out fadeInWithTransparency;
}

.indent {
  padding: 0 3em;
}

button, .button {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  transition: background-color .3s;
}

button:hover, .button:hover {
  background-color: var(--accent-color);
}

button.close, .button.close {
  color: var(--primary-color);
  background-color: #0000;
  padding: 2px;
  font-size: 1.2em;
}

@keyframes GradientAnimated {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

article {
  padding: var(--standard-space);
  box-sizing: border-box;
  color: var(--text-color);
  background-color: #ffffffe6;
  border-radius: 8px;
  width: 100%;
  max-height: 70vh;
  margin: 0 auto;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  animation: 1s ease-out fadeInWithTransparency;
  overflow: auto;
  box-shadow: 1px 1px 4px 1px #00000078;
}

article table {
  border-collapse: collapse;
  color: var(--table-font-color);
  margin: 20px 0;
  box-shadow: 2px 2px 20px 2px silver;
}

article table th, article table td {
  padding: var(--table-padding);
  border: 1px solid var(--table-border-color);
}

article table th {
  background-color: var(--table-header-bg);
  color: var(--table-header-color);
}

article table tr:nth-child(2n) {
  background-color: var(--table-row-alt-bg);
}

article table tr:nth-child(odd) {
  background-color: var(--table-row-bg);
}

article table tr:hover {
  background-color: #0000000d;
}

article table caption {
  text-align: left;
  color: var(--table-header-color);
  margin: 10px 0;
  font-size: 1.5em;
}

@media (width <= 768px) {
  article {
    padding: 15px;
  }
}

@media (width <= 480px) {
  article {
    padding: 10px;
  }
}

@media (width >= 1200px) {
  article {
    max-width: calc(80vw - 10em);
  }

  article li {
    margin-bottom: 1em;
  }
}

article p {
  text-align: justify;
  margin-bottom: 15px;
}

article .quote {
  margin: 0 4em;
  font-family: serif;
  font-style: italic;
}

article .box {
  padding: var(--standard-space);
  box-sizing: border-box;
  background: var(--opaque-background-color);
  border-radius: 8px;
  margin: 1em;
  box-shadow: 1px 1px 4px 1px #00000078;
}

article img {
  float: left;
  margin-right: var(--standard-space);
}

@media (width >= 1201px) {
  article img {
    width: 40%;
  }
}

@media (width <= 1024px) {
  article img {
    width: 100%;
  }
}

article li {
  text-align: justify;
  font-family: VerelaRound-Regular, Arial, Segoe UI, Tahoma, sans-serif;
  line-height: 1.6;
}

article a {
  color: var(--primary-color);
  text-decoration: none;
}

article a:hover {
  text-decoration: underline;
}

ul.checked-list {
  text-indent: -1em;
  margin-left: 0;
  padding-left: 1em;
  list-style: none;
}

ul.checked-list li:before {
  content: "✓";
  color: green;
  border-radius: 1em;
  width: 1em;
  padding-right: 5px;
  font-size: 2em;
  font-weight: bold;
}

.small-self-pic {
  min-width: 10em;
  max-width: 10vw;
}

.liability {
  font-size: .7em;
}

.announcement {
  font-style: italic;
}

.announcement.regular {
  color: orange;
  font-weight: bolder;
}

.announcement.important {
  color: orange;
  -webkit-text-stroke: 1px #000;
  font-size: 2em;
}

.announcement.cancellation {
  color: orange;
  font-size: 2em;
  font-weight: bolder;
}

.announcement.danger {
  color: red;
  font-size: 1.75em;
  font-weight: bolder;
}

@media screen and (width <= 1000px) {
  menu-manager {
    z-index: 10;
    position: absolute;
    top: 0;
  }

  menu-manager .openner {
    font-size: 3em;
    display: block;
    right: 1em;
  }

  menu-manager .the-menu {
    width: max-content;
    display: flex;
    position: absolute;
  }

  menu-manager .the-menu.closed {
    display: none;
  }

  menu-manager .the-menu.open nav {
    flex-direction: column;
    width: auto;
    display: flex;
  }
}

@media screen and (width >= 1001px) {
  menu-manager .openner {
    display: none;
  }
}

#app {
  background-image: url("background-pattern.1c5676c2.webp");
  background-repeat: repeat;
}

#app .whatsapp-link {
  opacity: .8;
  transform-origin: 0 0;
  transition: all 1s ease-in-out;
  position: fixed;
  top: 40%;
  left: 1em;
  transform: translate(0, 50%)rotate(-90deg);
}

@media (width <= 1200px) {
  #app .whatsapp-link {
    left: 0;
  }
}

#app .whatsapp-link:hover {
  transform: rotate(0)scale(1.5);
}

#app .more {
  list-style: none;
}

.sub-header {
  text-align: center;
}

.slogan2 {
  text-align: center;
  color: var(--special-color);
  margin: 1em;
  padding: 1em;
  font-size: 1.25em;
  font-weight: bold;
  transition: all 1s;
}

.slogan2:hover {
  background-color: var(--opaque-background-color);
}

h1 {
  margin: 0;
  font-weight: bolder;
}

.main-container {
  justify-content: center;
  display: flex;
}

.articles-list {
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  font-weight: bold;
  list-style: none;
  display: grid;
}

.articles-list .control {
  font-size: .8em;
}

.article-thumbnail {
  hyphens: auto;
  text-align: justify;
  border: 1px solid silver;
  flex-direction: column;
  align-items: start;
  height: 16em;
  margin: .5em;
  padding: 1em;
  transition: all .4s ease-in;
  display: flex;
  overflow: hidden;
}

.article-thumbnail:hover {
  background-color: #faebd7;
  overflow: auto;
}

.article-thumbnail .unfinished {
  opacity: .5;
}

.article-thumbnail .synopsis {
  font-size: .8rem;
  font-weight: normal;
}

.article-thumbnail a {
  text-align: start;
}

.article-thumbnail img {
  width: 70%;
  height: auto;
  margin: auto;
}

article-display[is="article-display"] article {
  text-shadow: 0 0 #000;
}

article-display[is="article-display"] article img {
  width: 40%;
  margin-right: 1em;
}

contact-form, [is="contact-form"] {
  background-blend-mode: color-dodge;
  background-color: #e7d3d3cf;
  background-image: url("background-pattern.1c5676c2.webp");
  border: none;
  border-radius: 1em;
  flex-flow: column wrap;
  width: 50%;
  min-width: 20em;
  height: 40%;
  min-height: 31em;
  padding: 3.5em;
  display: flex;
  box-shadow: 5px 11px 11px 7px #10134180;
}

@media (width <= 1400px) {
  contact-form, [is="contact-form"] {
    box-shadow: 2px 5px 5px 3px #10134180;
  }
}

contact-form .autoform > div, [is="contact-form"] .autoform > div {
  height: fit-content;
  margin: 1em .5em;
}

contact-form .form-main, [is="contact-form"] .form-main {
  justify-content: center;
  display: flex;
}

contact-form .form-main input, [is="contact-form"] .form-main input, contact-form .form-main textarea, [is="contact-form"] .form-main textarea, contact-form .form-main select, [is="contact-form"] .form-main select {
  border-style: solid;
  border-color: var(--primary-color);
  color: var(--opaque-background-color);
  background: var(--primary-color);
  border-top-left-radius: .5em;
}

contact-form .form-main .logo img, [is="contact-form"] .form-main .logo img {
  width: 5em;
}

footer {
  background-color: var(--primary-color);
  color: var(--opaque-background-color);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 1em;
  display: flex;
}

@media (width <= 1400px) {
  footer {
    padding: 1em .5em .25em .25em;
  }
}

footer a {
  color: var(--opaque-background-color);
}

footer button.contact {
  background-color: #000;
}

.under-construction {
  color: #000;
}

.info, .info .contact-details {
  gap: 1em;
  display: flex;
}

@media screen and (width <= 600px) {
  .super-points {
    text-align: center;
  }
}

.programs {
  text-align: center;
  background-color: var(--opaque-background-color);
  border-radius: 1em;
  padding: 2em 10%;
  box-shadow: 2px 2px 5px 5px silver;
}

@media screen and (width >= 1000px) {
  .programs {
    margin: 0 10% var(--standard-space) 10%;
  }
}

.contact-menu-item a {
  background-size: 200% 200%;
  background-image: linear-gradient(to right, var(--opaque-background-color) 0%, var(--accent-color) 50%, var(--opaque-background-color) 100%);
  border-radius: .5em;
  animation: 4s ease-in-out infinite GradientAnimated;
}

contact-widget {
  background-color: var(--special-color2);
  margin-top: 1em;
}

contact-widget label {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

contact-widget .content {
  background-size: 200% 200%;
  background-image: linear-gradient(to right, var(--opaque-background-color) 0%, var(--primary-color) 50%, var(--opaque-background-color) 100%);
  animation: 4s ease-in-out infinite GradientAnimated;
}

plans article > section, [is="plans"] article > section {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
}

plans .course, [is="plans"] .course {
  padding: var(--standard-space);
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: 1px 1px 4px 1px #00000078;
}

plans .course .offer-content, [is="plans"] .course .offer-content {
  font-weight: bold;
}

@media (width >= 1400px) {
  plans .course .offer-pricing, [is="plans"] .course .offer-pricing {
    font-size: .8rem;
  }
}

plans .course .offer-pricing .special-price h3, [is="plans"] .course .offer-pricing .special-price h3 {
  font-size: 1.5em;
  font-weight: bold;
}

plans .course .offer-pricing li, [is="plans"] .course .offer-pricing li {
  text-align: start;
}

welcome .read-more-stuff, [is="welcome"] .read-more-stuff, welcome-org .read-more-stuff, [is="welcome-org"] .read-more-stuff {
  text-align: center;
}

welcome .read-more-stuff ul, [is="welcome"] .read-more-stuff ul, welcome-org .read-more-stuff ul, [is="welcome-org"] .read-more-stuff ul {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  display: flex;
}

welcome .read-more-stuff ul li, [is="welcome"] .read-more-stuff ul li, welcome-org .read-more-stuff ul li, [is="welcome-org"] .read-more-stuff ul li {
  display: block;
}

welcome .welcome-top h1, [is="welcome"] .welcome-top h1, welcome-org .welcome-top h1, [is="welcome-org"] .welcome-top h1 {
  text-align: center;
}

welcome .welcome-top h3, [is="welcome"] .welcome-top h3, welcome-org .welcome-top h3, [is="welcome-org"] .welcome-top h3 {
  font-weight: bold;
}

welcome plans article, [is="welcome"] plans article, welcome-org plans article, [is="welcome-org"] plans article {
  background-color: var(--special-color2);
  width: fit-content;
}

welcome plans article .special-price, [is="welcome"] plans article .special-price, welcome-org plans article .special-price, [is="welcome-org"] plans article .special-price {
  color: var(--special-accent-color);
}

welcome plans article section, [is="welcome"] plans article section, welcome-org plans article section, [is="welcome-org"] plans article section {
  justify-content: space-evenly;
  max-width: 60em;
}

welcome plans article section h1, [is="welcome"] plans article section h1, welcome-org plans article section h1, [is="welcome-org"] plans article section h1, welcome plans article section h2, [is="welcome"] plans article section h2, welcome-org plans article section h2, [is="welcome-org"] plans article section h2, welcome plans article section h3, [is="welcome"] plans article section h3, welcome-org plans article section h3, [is="welcome-org"] plans article section h3 {
  color: var(--opaque-background-color) !important;
}

@media (width >= 1400px) {
  welcome plans article .offer-pricing, [is="welcome"] plans article .offer-pricing, welcome-org plans article .offer-pricing, [is="welcome-org"] plans article .offer-pricing {
    color: var(--opaque-background-color) !important;
    font-size: 1em !important;
  }

  welcome plans article h1, [is="welcome"] plans article h1, welcome-org plans article h1, [is="welcome-org"] plans article h1, welcome plans article h2, [is="welcome"] plans article h2, welcome-org plans article h2, [is="welcome-org"] plans article h2, welcome plans article h3, [is="welcome"] plans article h3, welcome-org plans article h3, [is="welcome-org"] plans article h3 {
    color: var(--opaque-background-color) !important;
  }
}

welcome article, [is="welcome"] article, welcome-org article, [is="welcome-org"] article {
  max-width: none;
  font-size: 1.4em;
}

welcome plans section, [is="welcome"] plans section, welcome-org plans section, [is="welcome-org"] plans section {
  flex-wrap: wrap;
  justify-content: space-between;
}

welcome plans section > div, [is="welcome"] plans section > div, welcome-org plans section > div, [is="welcome-org"] plans section > div {
  box-shadow: none;
  padding: calc(var(--standard-space) / 4);
}

welcome recommendations, [is="welcome"] recommendations, welcome-org recommendations, [is="welcome-org"] recommendations {
  font-size: 1rem;
}

@media screen and (width <= 1200px) {
  welcome recommendations, [is="welcome"] recommendations, welcome-org recommendations, [is="welcome-org"] recommendations {
    flex-direction: column;
  }
}

welcome article article, [is="welcome"] article article, welcome-org article article, [is="welcome-org"] article article {
  font-size: 1.25rem;
}

events article, [is="events"] article {
  min-width: 60vw;
  max-width: 80vw;
}

@media screen and (width <= 1000px) {
  events article, [is="events"] article {
    max-width: 95%;
  }
}

events article li, [is="events"] article li {
  border: .25em solid var(--accent-color);
  background-color: #c3d7ef;
  border-radius: 2px;
  min-height: 11em;
  padding: 2em;
  list-style: none;
  box-shadow: 5px 11px 11px 7px #10134180;
}

@media (width <= 1400px) {
  events article li, [is="events"] article li {
    box-shadow: 2px 5px 5px 3px #10134180;
  }
}

events img, [is="events"] img {
  transform-origin: center;
  width: 18%;
  height: auto;
  transition: transform .3s ease-in-out;
}

events img:hover, [is="events"] img:hover {
  z-index: 10;
  border: 2px solid var(--opaque-background-color);
  border-radius: .25em;
  transform: scale(1.75);
}

events details, [is="events"] details {
  max-width: 75%;
}

@media screen and (width <= 1000px) {
  events details, [is="events"] details {
    max-width: 95%;
  }
}

blog .blog-view, [is="blog"] .blog-view {
  display: flex;
}

@media screen and (width <= 1000px) {
  blog .blog-view, [is="blog"] .blog-view {
    flex-direction: column;
    max-width: 95%;
  }
}

blog .blog-view .last-post-pane, [is="blog"] .blog-view .last-post-pane {
  flex-grow: 1;
}

blog .blog-view .articles-list, [is="blog"] .blog-view .articles-list {
  border: 1px solid pink;
  border-radius: .5em;
  padding: 1em;
  overflow: auto;
}

blog .blog-view .articles-list li a, [is="blog"] .blog-view .articles-list li a {
  font-size: 1rem;
}

.dana-declaration {
  display: flex;
}

.dana-declaration > div {
  text-align: center;
  border: 1px solid silver;
  margin: 1em;
  padding: 1em;
  font-style: italic;
}
/*# sourceMappingURL=index.c372ed8a.css.map */
