@import "general.less";

@media screen and (max-width: 1000px) {
  menu-manager {

    position: absolute;
    top: 0;
    z-index: 10;


    .openner {
      display: block;
      font-size: 3em;
      right: 1em;
    }

    .the-menu {
      display: flex;
      position: absolute;
      width: max-content;

      &.closed {
        display: none;
      }

      &.open {

        nav {
          display: flex;
          flex-direction: column;
          width: auto;
        }

      }
    }

  }
}

@media screen and (min-width: 1001px) {
  menu-manager {
    .openner {
      display: none;
    }

    .the-menu {
      //flex-direction: row;
    }
  }
}


#app {

  background-image: url('resources/background-pattern.webp');
  background-repeat: repeat;

  .whatsapp-link {
    position: fixed;
    top: 40%;
    left: 1em;
    transform: translate(0, 50%) rotate(-90deg);
    opacity: 0.8;
    transform-origin: left top;
    transition: all 1s ease-in-out;

    @media (max-width: 1200px) {
      left: 0;
    }

    &:hover {
      transform: rotate(0) scale(1.5);
    }
  }

  .more {
    list-style: none;
    //@media (min-width: 1024px) {
    //  display: none;;
    //}
  }

}


.sub-header {
  text-align: center;
}

.slogan2 {
  text-align: center;
  margin: 1em;
  padding: 1em;
  font-size: 1.25em;
  font-weight: bold;
  color: var(--special-color);
  transition: all 1s ease;

  &:hover {
    background-color: var(--opaque-background-color);
  }
}

h1 {
  font-weight: bolder;
  //padding: 0 0 var(--standard-space) 0 ;
  margin: 0;
}

.main-container {
  display: flex;
  justify-content: center;

}

.articles-list {
  list-style: none;
  font-weight: bold;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 10px;

  .control {
    font-size: 0.8em;
  }

}

.article-thumbnail {

  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid silver;
  padding: 1em;
  margin: 0.5em;
  hyphens: auto;
  text-align: justify;
  height: 16em;
  overflow: hidden;
  transition: all 0.4s ease-in;

  &:hover {
    background-color: antiquewhite;
    overflow: auto;
  }

  .unfinished {
    opacity: 0.5;
  }

  .synopsis {
    font-weight: normal;
    font-size: 0.8rem;
  }

  a {
    text-align: start;
  }

  img {
    width: 70%;
    height: auto;
    margin: auto;
  }


}


article-display[is=article-display] {

  article {

    text-shadow: 0 0 black;

    img {
      width: 40%;
      margin-right: 1em;
    }
  }
}


contact-form, [is=contact-form] {
  .box-shadow();
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  min-width: 20em;
  border: none;
  border-radius: 1em;
  min-height: 31em;
  height: 40%;
  padding: 3.5em;

  .autoform > div {
    margin: 1em 0.5em;
    height: fit-content;
  }

  background-blend-mode: color-dodge;
  background-color: #e7d3d3cf;
  background-image: url("resources/background-pattern.webp");

  .form-main {
    display: flex;
    justify-content: center;

    input, textarea, select {
      border-style: solid;
      border-top-left-radius: 0.5em;
      border-color: var(--primary-color);
      color: var(--opaque-background-color);
      background: var(--primary-color);
    }

    .logo {
      img {
        width: 5em;
      }
    }
  }
}

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  padding: 1em;
  @media (max-width: 1400px) {
    padding: 1em 0.5em 0.25em 0.25em;
  }
  align-items: center;
  background-color: var(--primary-color);
  color: var(--opaque-background-color);

  a {
    color: var(--opaque-background-color);

  }

  button.contact {
    background-color: black;

  }
}

.under-construction {
  color: black;
}

.info {
  display: flex;
  gap: 1em;

  .name {

  }

  .contact-details {
    display: flex;
    gap: 1em;
  }
}

.super-points {

  @media screen and (max-width: 600px) {
    text-align: center;
  }
}


.programs {
  text-align: center;
  padding: 2em 10%;
  @media screen and (min-width: 1000px) {
    margin: 0 10% var(--standard-space) 10%;
  }
  box-shadow: 2px 2px 5px 5px silver;
  border-radius: 1em;
  background-color: var(--opaque-background-color);
}

.contact-menu-item {
  a {
    .gradient-item(var(--accent-color));
    border-radius: 0.5em;
  }
}

contact-widget {
  margin-top: 1em;
  background-color: var(--special-color2);


  @media (max-width: 600px) {
    //flex-direction: column;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content {
    .gradient-item(var(--primary-color));
  }

}

plans, [is=plans] {
  article {
    > section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  .course {
    .standard-box();
    border-radius: 0;

    .offer-content {
      font-weight: bold;
    }

    .offer-pricing {
      @media (min-width: 1400px) {
        font-size: 0.8rem;
      }

      .special-price {
        h3 {
          font-size: 1.5em;
          font-weight: bold;
        }
      }

      li > {
        text-align: start;
      }

    }
  }
}

welcome, [is=welcome], welcome-org, [is=welcome-org] {

  .read-more-stuff {
    text-align: center;

    ul {
      display: flex;
      justify-content: center;
      gap: 2em;
      flex-wrap: wrap;

      li {

        display: block;
      }
    }
  }

  .welcome-top {
    h1 {
      text-align: center;
    }

    h3 {
      font-weight: bold;
    }
  }

  plans article {
    background-color: var(--special-color2);
    width: fit-content;

    .special-price {
      color: var(--special-accent-color);
    }

    section {
      max-width: 60em;
      justify-content: space-evenly;

      h1, h2, h3 {
        color: var(--opaque-background-color) !important;
      }
    }
  }


  @media (width >= 1400px) {
    plans article {
      .offer-pricing {
        font-size: 1em !important;
        color: var(--opaque-background-color) !important;

      }

      h1, h2, h3 {
        color: var(--opaque-background-color) !important;
      }
    }
  }

  article {
    font-size: 1.4em;
    max-width: none;
  }

  img {

  }

  plans {
    section {
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        box-shadow: none;
        padding: ~"calc( var(--standard-space) / 4 )";
      }
    }
  }

  recommendations {
    font-size: 1rem;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  article article {
    font-size: 1.25rem;
  }
}

events, [is=events] {

  article {
    min-width: 60vw;
    max-width: 80vw;

    @media screen and (max-width: 1000px) {
      max-width: 95%;
    }

    li {
      border: 0.25em solid var(--accent-color);
      border-radius: 2px;
      min-height: 11em;
      padding: 2em;
      list-style: none;
      .box-shadow();
      background-color: #c3d7ef
    }
  }

  img {
    width: 18%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    transform-origin: center;
  }

  img:hover {
    //position: absolute;
    transform: scale(1.75);
    z-index: 10;
    border: 2px solid var(--opaque-background-color);
    border-radius: 0.25em;
  }

  details {
    max-width: 75%;
    @media screen and (max-width: 1000px) {
      max-width: 95%;
    }
  }

}

blog, [is=blog] {


  .blog-view {
    display: flex;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      max-width: 95%;
    }

    .last-post-pane {
      flex-grow: 1;
    }

    .articles-list {
      border: 1px solid pink;
      border-radius: 0.5em;
      padding: 1em;
      overflow: auto;

      li a {
        font-size: 1rem;
      }
    }
  }
}


.dana-declaration {
  display: flex;
  > div{
    font-style: italic;
    text-align: center;
    border: 1px solid silver;
    padding: 1em;
    margin: 1em;
  }

}